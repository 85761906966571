import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground";
import { SectionHeading } from "components/misc/Headings";
import SocialIcon from "components/SocialIcon";
import Hotel from "./Hotel";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Booking" }) => {
  return (
    <AnimationRevealPage>
      <SocialIcon />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <img src="/images/hotels/booking.jpg" alt="booking" width='100%'/>

            {/*<ul>
                <strong>Notes:</strong>
                <li> Bookings to be done online, strictly on <span style={{fontSize:'30px'}}>First Come First Serve Basis</span>.<br/>
                No Requests for Offline bookings will be entertained.</li>
                <li>Bookings shall be confirmed upon 100% Payment only. No Part Payments shall be accepted.</li>
                <li>No Cancellation will be possible once the booking has been confirmed. Requests for
                transfer of Names can be made within the same club upto 15th December, 2023</li>
                <li>Check in time – 2:00 PM & Check Out time – 12:00 Noon. Early Check-Ins and late check
                outs not available.</li>
                <li>Extra bed Charges and Room Service availed, if any, to be settled directly with the Hotel</li>
            </ul>

            <h2 style={{color:'purple',textAlign:'center'}}><i><u>ACCOMMODATION COMMITTEE</u></i></h2>
            <div className="row text-center">
                <div className="col-lg-3 col-md-6 col-sm-6 shadow-2xl text-primary">
                    <p><i>RTN MUKUL BOTHRA</i></p>
                    <p><i>ACCOMODATION CHAIR</i></p>
                    <p><i>ROTARY CLUB OF TEZPUR</i></p>
                    <p><i>99571 81036</i></p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 shadow-2xl text-primary">
                    <p><i>RTN BIKASH JAIN</i></p>
                    <p><i>ROTARY CLUB OF GREATER TEZPUR</i></p>
                    <p><i>99540 31620</i></p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 shadow-2xl text-primary">
                    <p><i>RTN AMIT SONI</i></p>
                    <p><i>ROTARY CLUB OF NAGAON</i></p>
                    <p><i>94357 10030</i></p>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 shadow-2xl text-primary">
                    <p><i>RTN ANIRUDH TIBREWAL</i></p>
                    <p><i>ROTARY CLUB OF TEZPUR</i></p>
                    <p><i>94350 81814</i></p>
                </div>
              </div> */}
          </Text>
          <Hotel/>
          <Hotel/>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
