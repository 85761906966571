import React, { useState } from "react";
import { Container } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { SectionHeading } from "components/misc/Headings";
import { Link } from "react-router-dom";
import './Hotel.css'

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Hotel" }) => {
  const [ isDisplay,setIsDisplay ] = useState(false)
  const [ selectRooms,setSelectRooms ] = useState(0)

  return (<>
      <Container>
          <Text>
            <div className="row">
              <div className="col-lg-12 shadow-2xl card">
                <div>
                  <img src='/images/hotels/club_nirvana.jpg' alt=''/>
                </div>
                <div className="p-2">
                  <h3>DICHANG RESORT</h3>
                  <h4>Sonapur, Guwahati, Assam</h4>
                  <small><i>Distance from Venue 0KM</i></small>
                  <p>Breakfast at Venue Hotel</p>
                  <ul>
                    <li>Executive Room</li>
                    <li>Deluxe Room</li>
                    <li>Supreme Rooms</li>
                    <li>Available Extra Beds</li>
                  </ul>
                </div>
                <div className="div-btn">
                  <button className="btn btn-primary" onClick={()=>setIsDisplay(!isDisplay)}>See Availability</button>
                </div>
              </div>
            </div>

            { isDisplay && <Text>
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Room Type</th>
                    <th scope="col">Rooms</th>
                    <th scope="col">Price/- (per night)</th>
                    <th scope="col">Select Rooms</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Executive </th>
                    <td>10</td>
                    <td>4500</td>
                    <td><input type="number" value={selectRooms} onChange={e=>setSelectRooms(e.target.value)}/></td>
                  </tr>
                  <tr>
                    {
                      (selectRooms>0 && selectRooms<=10)
                      ? <td colSpan={'5'} style={{textAlign:'right'}}>Total: 9000/- <Link className="btn btn-primary" to={'/checkout_page'}>Book Now</Link></td> 
                      : <td colSpan={'5'} style={{textAlign:'right'}}>Total: 9000/- <button className="btn btn-primary" disabled>Book Now</button></td>
                    }
                  </tr>
                </tbody>
              </table>
            </Text>
            }
          </Text>
      </Container>
 </>)
};
